/* This stylesheet generated by Transfonter (https://transfonter.org) on February 26, 2018 6:37 AM */

@font-face {
    font-family: 'Gotham';
    src: url('GothamBold.woff') format('woff'),
        url('GothamBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('GothamBold-Italic.woff') format('woff'),
        url('GothamBold-Italic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-Medium.woff') format('woff'),
        url('Gotham-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-Book.woff') format('woff'),
        url('Gotham-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('GothamBook-Italic.woff') format('woff'),
        url('GothamBook-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}
