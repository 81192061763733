    /*
    Flaticon icon font: Flaticon
    Creation date: 26/02/2018 11:17
    */

    @font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

    .fi:before{
        display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
    }

    .flaticon-pdf-rounded-rectangular-outlined-file-type-symbol:before { content: "\f100"; }
.flaticon-arrows-1:before { content: "\f101"; }
.flaticon-arrows:before { content: "\f102"; }
.flaticon-zoom-in:before { content: "\f103"; }
.flaticon-magnifying-glass:before { content: "\f104"; }
.flaticon-correct-symbol:before { content: "\f105"; }
.flaticon-facebook-placeholder-for-locate-places-on-maps:before { content: "\f106"; }
.flaticon-loading:before { content: "\f107"; }
.flaticon-close:before { content: "\f108"; }
.flaticon-right-arrow-1:before { content: "\f109"; }
.flaticon-left-arrow-1:before { content: "\f10a"; }
.flaticon-right-arrow:before { content: "\f10b"; }
.flaticon-left-arrow:before { content: "\f10c"; }
.flaticon-cross-out:before { content: "\f10d"; }
    
    $font-Flaticon-pdf-rounded-rectangular-outlined-file-type-symbol: "\f100";
    $font-Flaticon-arrows-1: "\f101";
    $font-Flaticon-arrows: "\f102";
    $font-Flaticon-zoom-in: "\f103";
    $font-Flaticon-magnifying-glass: "\f104";
    $font-Flaticon-correct-symbol: "\f105";
    $font-Flaticon-facebook-placeholder-for-locate-places-on-maps: "\f106";
    $font-Flaticon-loading: "\f107";
    $font-Flaticon-close: "\f108";
    $font-Flaticon-right-arrow-1: "\f109";
    $font-Flaticon-left-arrow-1: "\f10a";
    $font-Flaticon-right-arrow: "\f10b";
    $font-Flaticon-left-arrow: "\f10c";
    $font-Flaticon-cross-out: "\f10d";