::selection {
    color: $white;
    background: $primaryColor4;
}


::-webkit-input-placeholder {
    color: $placeholderColor !important;
}

 :-moz-placeholder {
    color: $placeholderColor !important;
}

 ::-moz-placeholder {
    color: $placeholderColor !important;
}

:-ms-input-placeholder {
    color: $placeholderColor !important;
}

em{
    font-style: italic;
}

a {
    color: $secondaryColor;
    outline: none !important;
    &:hover {
        color: darken($primaryColor2, 15%);
    }
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
    font-family: $headingFont;
    color: $primaryColor1;
    font-weight: bold;
}

h1,
.h1 {
    font-size: $h1Size;
}

h2,
.h2 {
    font-size: $h2Size;
}

h3,
.h3 {
    font-size: $h3Size;
}

h4,
.h4 {
    font-size: $h4Size;
}

p {
   // margin-bottom: 20px;
}

.btn {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    border: none;
    padding: 9px 20px;
    line-height: 1;
    border-radius: 0;
    box-shadow: none !important;
    position: relative;
    transition: all 0.3s;
    letter-spacing: .05em;
    &.btn-primary{
        background-color: $secondaryColor !important;
        &:hover{
          background-color: lighten($secondaryColor, 5%) !important;
        }
    }
}

.btn-secondary {
    border: 2px solid $primaryColor1 !important;
    color: $white !important;
    background-color: $primaryColor1 !important;
    &:hover {
        background-color: lighten($primaryColor1, 5%) !important;
    }
}

.btn-outline-secondary {
    border: 2px solid $primaryColor1 !important;
    color: $white !important;
    &:hover {
        background-color: $primaryColor1 !important;
    }
}


.wrapper {
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
}

body {
    font-family: $bodyFontFamily;
    font-size: $bodyFontSize;
    line-height: $bodyLineHeight;
    overflow-x: hidden;
    color: $bodyTextColor;
    scroll-behavior: smooth;
}


// hamburger
.hm {
    position: relative;
    padding: 20px;
    display: block;
    text-decoration: none;
}

.hamburger {
    position: relative;
    width: 40px;
    height: 30px;
    cursor: pointer;
    border: 5px solid transparent;
    span {
        width: 100%;
        height: 2px;
        background: $primaryColor1;
        display: block;
        position: absolute;
        left: 0;
        transition: all 0.3s;
        &.h-top {
            top: 0;
        }
        &.h-bottom {
            bottom: 0;
            width: 27px;
        }
        &.h-middle {
            top: 50%;
            margin-top: -1px;
            width: 25px;
        }
    }
    &.h-active {
        span {
            width: 100%;
            &.h-top {
                transform: rotate(45deg);
                top: 50%;
                margin-top: -1px;
            }
            &.h-middle {
                transform: translateX(30px);
                opacity: 0;
            }
            &.h-bottom {
                transform: rotate(-45deg);
                bottom: 50%;
                margin-bottom: -1px;
            }
        }
    }
}

.header-wrap{
    height: 56px;
}


.header-main {
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    border-bottom: 1px solid $lightGray2;
    background-color: $white;
    transform: translate3d(0, 0, 0);
    &.header-active{
        position: fixed;
    }
}



.main-nav {
    .nav {
        @include resetUl;
        margin-right: -12px;
        >li {
            position: relative;
            padding: 0 12px;
            > a {
                display: block;
                text-decoration: none;
                color: $navColor;
                font-size: $bodyFontSize - 2;
                line-height: 1;
                position: relative;
                transition: color 0.3s;
                outline: none;
            }
            &:hover,
            &.active-nav {
                a {
                    color: $primaryColor1;
                }
            }
            &.active-nav {
                a {
                    font-weight: 500;
                }
            }
        }
    }
}



.headermob {
    width: 100%;
    height: 70px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    backface-visibility: hidden;
    background-color: rgba($white, .9);
    border-bottom: 1px solid $lightGray3;
    z-index: 9999;
    .brand {
        width: 100%;
        text-align: center;
        padding-right: 80px;
        a {
            display: inline-block;
            img {
                width: 168px;
                height: 56px;
            }
        }
    }
}


.brand {
    img {
        width: 168px;
        height: 56px;
    }
}


.slideout-menu {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    width: 256px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    display: none;
    background-color: $primaryColor1;
}

.slideout-panel {
    position: relative;
    z-index: 1; // will-change: transform;
    background-color: $white;
    min-height: 100vh;
    box-shadow: -10px 0 15px rgba(0, 0, 0, .1);
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
    overflow: hidden;
}

.slideout-open .slideout-menu {
    display: block;
}


#menumob {
    padding: 20px;
    >ul {
        @include resetUl;
        >li {
            &.social {
                padding-top: 20px;
            }
            >a {
                font-size: 16px;
                display: block;
                color: $white;
                display: block;
                text-decoration: none;
                padding: 12px 0;
                border-bottom: 1px solid rgba(0,0,0,.2);
            }
        }
    }

    .social {
        ul {
            li {
                padding: 0 4px;
                a {
                    font-size: 16px;
                    width: 34px;
                    height: 34px;
                    line-height: 34px;
                }
            }
        }
    }
}


[data-type="content"] {
    backface-visibility: hidden;
}


.logo {
    // flex-basis: 330px;
    // max-width: 330px;
    margin-right: auto;
    margin-left: 105px;
    text-align: center;
    a {
        display: inline-block;
        img {
            width: 94px;
        }
    }
}

.submenu {
    padding-top: 12px;
    position: absolute;
    left: 15px;
    bottom: auto;
    min-width: 150px;
    opacity: 0;
    visibility: hidden;
    z-index: 999;
    transform: translateY(-20px);
    transition: all 0.3s;
    ul {
        background-color: $white;
        box-shadow: 0 4px 10px rgba($black, .1);
        @include resetUl;
        padding: 15px;
        border-radius: 0 0 2px 2px;
        >li {
            display: block;
            margin: 0 r(-15px);
            a {
                display: block;
                padding: 8px 15px;
                color: $black;
                line-height: normal;
                font-size: 14px;
                text-decoration: none;
                transition: all 0.3s;
                &:hover {
                    color: $white !important;
                    background-color: $primaryColor1;
                }
            }
        }
    }
}

.main-nav {
    .nav {
        >li {
            &:hover {
                .submenu {
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0);
                }
            }
        }
    }
}


.main-content {}

.section {
    padding: 75px 0;
}

.section-hero {
    padding: 0;
    position: relative;
    overflow: hidden;
    color: $white;
    &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        opacity: .5;
        background: $primaryColor1;
    }
    .container {
        position: relative;
        z-index: 2;
    }
    .intro {
        &.intro-home{
          min-height: 500px;
          height: calc(100vh - 56px);
          > .row{
             position: relative;
             top: 50%;
             transform: translateY(-50%);
          }
        }
    }
}

.hero-content {
   .title{
    margin-bottom: 17px;
      h1,h2{
        color: $white;
        font-size: 48px;
      }
      &:after{
        width: 180px;
      }
   }
    p {
        font-size: 30px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.herobg {
    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-position: center bottom;
    background-color: $primaryColor1;
}

.scrollto {
    position: absolute;
    width: 30px;
    height: 50px;
    line-height: 1;
    text-align: center;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 65px;
    font-size: 32px;
    color: $white;
    i{
         display: inline-block;
         position: relative;
         animation: sdb05 1.5s infinite 0s;
         &:first-child{
            position: relative;
            top: 12px;
         }
         &:last-child{
            animation-delay: .15s;
         }
    }
}

@keyframes sdb05 {
    0% {
        transform: translateY(0);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: translateY(20px);
        opacity: 0;
    }
}

.title {
    padding-bottom: 20px;
    margin-bottom: 40px;
    position: relative;
    &:after {
        content: "";
        display: block;
        width: 120px;
        height: 2px;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: $secondaryColor;
    }
    &.text-center{
        &:after{
            right: 0;
            margin: auto;
            bottom: 0;
        }
    }
    h1,h2{
      margin: 0;
    }
    h2 {
        font-size: 24px;
        line-height: 1;
        font-weight: normal;
    }
}


.section-about{
    h3{
        font-weight: 500;
        font-size: 18px;
    }
    .abt-content{
        max-width: 325px;
    }
}


.msus{
    width: 115px;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    padding: 35px 10px;
    color: $white;
    border-radius: 7px;
    line-height: 1;
    background-color: $lightGray2;
}



.social-header {
    .social {
        @media(min-width: 1200px) {
            margin-top: -3px;
        }
    }
}

.social {
    margin-right: -2px;
    ul {
        @include resetUl;
        li {
            padding: 0 2px;
            a {
                display: block;
                width: 24px;
                height: 24px;
                line-height: 24px;
                text-align: center;
                color: $white !important;
                font-size: 11px;
                background-color: $primaryColor1;
                transition: background-color 0.3s;
                text-decoration: none !important;
                &:hover {
                    background-color: darken($primaryColor4, 5%);
                }
            }
        }
    }
}

.section-contact {
    font-size: 14px;
}

.form-group {
    margin-bottom: 20px;
}

.form-control {
    font-size: 14px;
    height: 40px;
    padding-left: 19px;
    border-radius: 0;
    border-color: #898989;
}

textarea.form-control {
    height: 180px;
}

strong {
    font-weight: 600;
}

.addr {
    margin-left: 10px;
    font-size: $bodyFontSize;
    padding: 26px 30px;
    background-color: $primaryColor3;
    border: 1px solid #7cafe1;
    address {
        margin-bottom: 0;
        max-width: 250px;
        line-height: normal;
        h4 {
            margin-bottom: 20px;
            font-size: 22px;
            font-weight: normal;
            font-family: $headingFont;
            color: $secondaryColor;
        }
        h5 {
            margin-bottom: 0;
            text-transform: uppercase;
            font-size: $bodyFontSize;
            font-weight: bold;
            font-family: $headingFont;
            color: $primaryColor1;
        }
    }
    *:not(a) {
        color: #464646;
    }
    a {
        text-decoration: underline;
    }
}

.chbox {
    padding: 5px 0;
    input[type="checkbox"] {
        display: none;
    }
    label {
        font-weight: normal;
        position: relative;
        padding-left: 30px;
        cursor: pointer;
        margin-bottom: 0;
        color: #555555;
        &:before {
            content: "";
            font-family: "Flaticon";
            font-size: 12px;
            text-align: center;
            line-height: 20px;
            width: 20px;
            height: 20px;
            border: 1px solid #555555;
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -10px;
            color: $primaryColor1;
            background-color: $white;
            transition: all 0.3s;
        }
    }

    input[type="checkbox"]:checked+label {
        &:before {
            content: "\f10a";
            color: $white;
            background-color: $primaryColor1;
        }
    }
}

.note {
    margin: 8px 0 0;
    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}


.bg-gray {
    background-color: #f9f9f9;
}

.doclist {
    border: 1px solid $secondaryColor;
    padding: 25px 25px;
    width: 275px;
    h3 {
        font-family: $bodyFontFamily;
        font-size: 16px;
        font-weight: 500;
        color: $secondaryColor;
        margin-bottom: 15px
    }
    ul {
        margin-bottom: 0;
        li {
            padding: 7px 0;
            a {
                font-size: 14px;
                text-transform: uppercase;
                display: inline-block;
                color: $bodyTextColor;
                padding-left: 40px;
                line-height: 1;
                position: relative;
                text-decoration: none;
                transition: color .3s;
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    width: 27px;
                    transform: translateY(-50%);
                }
                &:hover {
                    color: $primaryColor1;
                }
            }

          &.pdf{
            a{
                &:after{
                  content: url(../images-lha/pdf.svg);
                }
             }
          }

          &.xlx{
            a{
                &:after{
                  content: url(../images-lha/xlx.svg);
                }
             }
          }




        }
    }
}

.etf-top {
    margin-bottom: 40px;
}

.data-row {
    thead {
        tr {
            &:first-child {
                background-color: #a2a4a7;
                td,
                th {
                    vertical-align: middle !important;
                    padding: 7px 15px;
                    border: none;
                }
                th:nth-child(1) {
                    font-weight: 500;
                    font-size: 16px;
                    font-family: $headingFont;
                }
            }
        }
        th {
            font-weight: 500;
        }
        th,
        td {
            color: $white !important;
            a {
                color: inherit;
                text-decoration: underline;
            }
        }
    }
}


.table {
    border-bottom: 1px solid $primaryColor1;
    border-top: 1px solid $primaryColor1;
    font-size: 14px;
    th {
            font-weight: 500;
        }
    th,
    td {
        padding: 7px 15px;
        color: $bodyTextColor;
        border-color: #849eb4;
    }

    .bodyhead {
        th,
        td {
            color: $primaryColor1 !important;
            border-width: 1px;
            font-weight: 500;
        }
    }
}

.table{
    .has-border{
       border-bottom: 1px solid #d6e3f4;
       // background-color:  #eef3fa;
    }
}


.table {
    th,
    td {
        border-color: #d7e5f5;
    }
    &.tstrip {
        tr:nth-child(even) {
            background-color: #f6f9fd;
        }
    }
    &.no-border {
        th,
        td {
            border: none;
        }
    }

    &.table-hover {
        tbody {
            tr {
                &:hover {
                    background-color: $primaryColor3 !important;
                }
            }
        }
    }
}


.data-info {
    font-size: 14px;
    margin-bottom: 30px;
    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
    .btn {
        margin-top: 5px;
        font-size: 14px;
        padding: 10px 20px;
    }
}


.etf-content {
    .title{
        margin-bottom: 25px;
    }
    ul {
        @include resetUl;
        li {
            position: relative;
            padding: 3px 0 3px 30px;
            p:last-child {
                margin-bottom: 0;
            }
            &:after {
                content: "";
                width: 4px;
                height: 4px;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: 12px;
                background-color: $secondaryColor;
            }
        }
    }
}


.fancybox-close-small {
    top: 15px;
    right: 15px;
    &:after {
        outline: none !important;
        width: 34px;
        height: 34px;
        font-size: 30px;
        content: "\f10d";
        font-family: "Flaticon";
        color: $primaryColor1 !important;
        background-color: transparent !important;
    }
    &:hover {
        &:after {
            color: $primaryColor4 !important;
        }
    }
}

.fancybox-bg {
    background: $primaryColor1;
}

.fancybox-is-open .fancybox-bg {
    opacity: .75;
}

.popup{
    background-color: $secondaryColor;
    h2{
        font-size: 20px;
    }
    *{
      color: #fff;
    }
}

.pop-content{
  a{
    text-decoration: none;
    display: inline-block;
    line-height: 1;
    border-bottom: 1px solid rgba($white, .5);
    &:hover{
        color: $primaryColor1;
        border-color: $primaryColor1;
    }
  }
}


#subscribe {
    .btn {
        letter-spacing: .05em;
    }
    .title {
        margin-bottom: 0;
    }
}

.loader-form {
    border: 5px solid #f3f3f3;
    border-top: 5px solid $primaryColor1;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.btn-wrap {
    padding-top: 15px;
}

.section-about {
    .section-content {
        font-size: 22px;
    }
}

.section-contact {
    .cnote {
        font-size: 22px;
        line-height: normal;
        margin-bottom: 30px;
    }
}

.footer-main {
    .social {
        margin-top: 20px;
        ul {
            li {
                padding: 0 4px;
                a {
                    font-size: 16px;
                    width: 34px;
                    height: 34px;
                    line-height: 34px;
                }
            }
        }
    }
    .copyrights {}
}

.footer-top {
    font-size: 14px;
    background-color: $primaryColor1;
    padding: 25px 0;
    color: $white;
}

.footer-address {
    border-left: 1px solid $white;
    padding-left: 25px;
    address {
        font-size: 13px;
        margin-bottom: 0;
        line-height: 1.3;
        >div {
            margin-top: 10px;
        }
        a {
            color: $primaryColor2;
            display: inline-block;
            line-height: 1;
            border-bottom: 1px solid $primaryColor2;
            text-decoration: none;
        }
    }
}

.footer-bottom {
    padding: 60px 0;
    color: #464646;
    background-color: $lightGray3;
    font-size: 14px;
    p:last-child {
        margin-bottom: 0;
    }
    h4 {
        font-size: 18px;
    }
    h1,
    h2,
    h3,
    h4 {
        margin-bottom: 20px;
        font-weight: 600;
    }
}

.footer-logo {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    img {
        width: 191px;
        height: 63px;
    }
}

.section-news {
    padding-top: 60px;
    padding-bottom: 90px;
    background-color: $lightGray3;
    .btn-light {
        top: -5px;
    }
}

.newsbox {
    padding: 18px 18px 35px 18px;
    border-bottom: 3px solid $primaryColor1;
    background-color: $white;
    color: $black;
    height: 100%;
    position: relative;
    a{
        text-decoration: none;
        color: $bodyTextColor;
        transition: color 0.3s;
        &:hover{
            color: $primaryColor1;
        }
    }
    h2 {
        font-weight: normal;
        font-size: $bodyFontSize + 2;
        color: inherit;
        padding-bottom: 25px;
    }
    p {
        font-family: $headingFont;
        margin-bottom: 10px;
        line-height: 1.3;
    }
    .btn {
        position: absolute;
        bottom: 18px;
    }
    .pb-name{
      color: $secondaryColor;
      font-size: 16px;
      font-style: italic;
      position: absolute;
      bottom: 25px;
      line-height: 1;
    left: 0;
    padding: 0 15px;
    }

}

.date {
    line-height: 1;
    color: $white;
    font-size: 14px;
    padding: 12px 15px;
    min-width: 94px;
    position: relative;
    margin: -18px -18px 25px;
    background-color: $primaryColor1;
}





.contactpop {
    .addr {
        margin-left: 0;
        address {
            max-width: 290px;
        }
    }
}

.section-privacy-policy {
    h2 {
        font-size: 24px;
        margin-bottom: 0;
    }
}

.fadeIn,
.fadeInUp,
.fadeInDown {
    visibility: hidden;
    animation-duration: 2s;
    animation-fill-mode: both;
}

.animActive {
    .hero-content {
        .title {
            h1,
            h2,
            h3 {
                visibility: visible;
                animation-name: fadeInUp;
            }
            span {
                visibility: visible;
                animation-name: width;
            }
        }
        .fadeInUp {
            visibility: visible;
            animation-name: fadeInUp;
        }
    }

    .fadeIn,
    .fadeInUp,
    .fadeInDown {
        visibility: visible;
    }


    .fadeIn {
        animation-name: fadeIn;
    }

    .fadeInUp {
        animation-name: fadeInUp;
    }

    .fadeInDown {
        animation-name: fadeInDown;
    }
}




@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeInDown {
    from {
        visibility: hidden;
        transform: translateY(20px);
        opacity: 0;
    }

    to {
        visibility: visible;
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fadeInUp {
    from {
        visibility: hidden;
        transform: translateY(-20px);
        opacity: 0;
    }

    to {
        visibility: visible;
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes width {
    from {
        visibility: hidden;
        width: 0;
    }
    to {
        visibility: visible;
        width: 50px;
    }
}

.fancybox-container {
    font-family: inherit;
}


.loader-btn {

}


.spinner {
    display: inline-block;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    position: relative;
}

.spinner:after,
.spinner:before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $secondaryColor;
    opacity: .6;
    position: absolute;
    top: 0;
    left: 0;
    animation: sk-bounce 2s infinite ease-in-out;
}

.spinner:after {
    animation-delay: -1s;
}

@keyframes sk-bounce {
    0%,
    100% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
}


.innerhero {
    .content-block {
        padding-top: 180px !important;
        border: none !important;
    }
}

.team-img {
    width: 210px;
    img {
        max-width: 177px;
    }
}

.team-content {
    table {
        margin-bottom: 20px;
        td:first-child {
            font-style: italic;
            font-size: 18px;
            line-height: 1.3;
            color: $primaryColor1;
        }
        td:nth-child(2) {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}


.team-row {
    padding: 45px 0 25px;
    border-bottom: 1px solid $primaryColor1;
    &:nth-of-type(1) {
        padding-top: 25px !important;
    }
    &:last-child {
        border-bottom: none;
    }
}

.tinfo {
    margin-bottom: 25px;
    h3 {
        color: $secondaryColor;
        margin-bottom: 0;
    }
    h4 {
        font-family: $bodyFontFamily;
        font-style: italic;
        font-weight: normal;
    }
}

.page-heading {
    .content-block {
        text-align: center;
        padding-top: 190px !important;
        padding-bottom: 40px !important;
        h2 {
            color: $white;
            font-size: 45px;
        }
    }
}

.news-row {
    padding: 20px 0;
    border-bottom: 1px solid $secondaryColor;
    display: block;
    text-decoration: none !important;
    color: inherit !important;
    font-size: 18px;
    position: relative;
    transition: all 0.3s;
    &:hover {
        background-color: rgba(#dae6fa, .25);
    }
}

.news-info {
    width: 160px;
    span,
    strong {
        display: block;
        font-size: 16px;
    }
    strong {
        color: $secondaryColor;
        font-weight: 500;
    }
}

.news-content {
    font-size: 16px;
    h4 {
        font-size: 16px;
        color: $bodyTextColor;
        font-weight: 500;
    }
    .pname {
        display: block;
        font-weight: bold;
        font-style: italic;
        color: $primaryColor1;
    }
}

.newslist{
    margin-top: -30px;
}

.loader {
    padding-top: 30px;
}

.product-hero {
    .intro {
        padding-top: 190px;
        padding-bottom: 60px;
        min-height: 0;
    }
}

.note{
    font-size: 13px;
    padding-top: 60px;
    p:last-child{
        margin-bottom: 0;
    }
}

.page-hero{
    .intro{
        min-height: 0;
        padding-top: 125px;
        padding-bottom: 125px;
    }
    &.dark-overlay{
        &:before{
            opacity: .65;
        }
    }
}

.etf-content{
    font-size: 14px;
    h3,h4,h5{
        font-weight: 500;
        color: inherit;
    }
    h4{
        font-size: $bodyFontSize;
    }
}
