
$primaryColor1: #0f406b;
$primaryColor2: #a0bce6;
$primaryColor3: #eef3fa;
$primaryColor4: #6078de;
$secondaryColor: #d04827;
$navColor: #62656a;
$bodyTextColor: #62656a;
$placeholderColor: #898989;
$white: #fff;
$black: #000;
$lightGray1: #a2a4a7;
$lightGray2: #acacac;
$lightGray3: #ebebeb;
$bodyFontSize: 16px;
$bodyLineHeight: 1.5;
$bodyFontFamily: 'Gotham', sans-serif;
$headingFont: "Gotham", sans-serif;
$h1Size: 45px;
$h2Size: 34px;
$h3Size: 24px;
$h4Size: 20px;


@mixin resetUl {
  list-style: none;
  margin: 0;
  padding: 0;
}

$baseFontSize : $bodyFontSize;
@function r($pixels, $context: $baseFontSize) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1rem;
}