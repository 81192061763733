@media (min-width: 1200px){
  .container{
      max-width: 970px;
  }
}
@media(max-width: 1199px){
  .header-wrap{
     display: none !important;
  }

.page-hero .intro {
    padding-bottom: 75px;
}



}



@media(max-width: 991px){

.addr {
    margin-left: 0;
    }


.doclist{
  margin-top: 30px;
}

.newsbox{
  margin-bottom: 30px;
  height: auto;
}


}



@media(max-width: 767px){
   .hero-content {
      .title{
        h1{
        font-size: 37px;
      }
      }
      p{
        font-size: 20px;
      }
   }



.section-news{
  padding-bottom: 60px;
}


.footer-address{
  border: none;
  padding: 15px 0 15px;
}


}



@media(max-width: 575px){
  .popup{
    padding-left: 0;
    padding-right: 0;
     .btn-wrap{
       > div{
        &:first-child{
           padding-bottom: 20px;
        }
       }
     }
  }

   .data-info .btn{
     width: auto;
     white-space: normal;
   }

.news-info {
    width: auto;
    line-height: 1.2;
    padding-bottom: 10px;
    font-size: 14px;
}

.msus{
  margin-bottom: 20px;
}

.abt-content{
  max-width: none;
}






}

